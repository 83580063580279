import * as React from "react";
import { FC } from "react";
import { SitemapEntrypoint } from "../../entrypoints/sitemap";
import { LANG } from "../../common/types/localization";
import { DefaultLayout } from "../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.deCH} entrypoint={"sitemap"} page={"sitemap"}>
    <SitemapEntrypoint lang={LANG.deCH} />
  </DefaultLayout>
);
